const binding = { modules: {}, dataActions: {} };

    (binding.modules['guldfynd'] = {
        c: () => require('partner/themes/iduna-theme/iduna-theme.tsx'),
        $type: 'themeModule',
        da: [],
        
        iNM: false,
        ns: '__local__',
        n: 'guldfynd',
        p: '__local__',
        
        pdp: 'src/themes/iduna-theme/iduna-theme.definition.json',
        
        themeSettings: 'guldfynd.theme.settings.json',
        md: 'src/themes/guldfynd'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };